<template>
  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.74298 1.23499H1.74298V8.23499H8.74298V1.23499Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.743 1.23499H12.743V8.23499H19.743V1.23499Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.743 12.235H12.743V19.235H19.743V12.235Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.74298 12.235H1.74298V19.235H8.74298V12.235Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconGrid'
}
</script>
